import React from 'react';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import Modal from '@epcbuilder/lib/components/Modal';
import { Absence } from '@epcbuilder/lib/models/assessors';

export const DeleteAbsenceModal = ({
  value,
  onClose,
  callback,
}: {
  value: Absence;
  onClose: () => void;
  callback: () => Promise<void>;
}) => {
  const formatDateTime = (dateString: string | Date) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  return (
    <Modal id="delete-absence-modal" onClose={onClose}>
      <h1 className="font-header text-lg">Are you sure?</h1>
      <p className="mt-4">
        You are about to remove absence from <span className="font-bold">{formatDateTime(value.absenceDateStart)}</span>{' '}
        until <span className="font-bold">{formatDateTime(value.absenceDateEnd)}</span>.
      </p>
      <div className="mt-8 flex flex-row justify-between gap-4">
        <NewButton id="cancel-button" variant="secondary" onClick={onClose} text="Cancel" />
        <NewButton
          id="absence-delete-confirm"
          variant="primary"
          onClick={async () => {
            await callback();
            onClose();
          }}
          text="Confirm"
        />
      </div>
    </Modal>
  );
};

export default DeleteAbsenceModal;
