/* eslint-disable jsx-a11y/no-autofocus */
import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  MdCheck,
  MdClose,
  MdDeleteOutline,
  MdOutlineCropRotate,
  MdOutlineDriveFileRenameOutline,
  MdOutlineUploadFile,
} from 'react-icons/md';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { Button, Modal } from '@epcbuilder/lib/components';
import { rotateBase64Image } from '@epcbuilder/lib/utils';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { isJSONString } from '@epcbuilder/lib/utils/generic';
import { AxiosResponse } from 'axios';
import { KeyedMutator } from 'swr';
import { JobReport } from '@/models/job';

export const FileUpload = ({
  name,
  jobReport,
  refetchJobReport,
  callback,
}: {
  name: string;
  jobReport?: JobReport;
  refetchJobReport: () => void;
  callback: ({ id, data }: { id?: string; data: { value: string } }) => Promise<void | AxiosResponse<unknown, unknown>>;
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      try {
        if (!event.target.files) throw Error;

        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async () => {
          const base64Data = reader.result?.toString();
          if (!base64Data) throw Error;

          await callback({
            id: jobReport?.id,
            data: { value: base64Data },
          });
          toast.success(`${name} uploaded successfully`, { toastId: `${name}-success` });
          refetchJobReport();
        };

        reader.readAsDataURL(file);
      } catch (error: unknown) {
        handleUnknownDetail(error);
      }
    },
    [callback, jobReport, name, refetchJobReport]
  );

  const handleFileClick = useCallback(() => {
    const fileInput = ref;
    fileInput.current?.click();
  }, []);

  return (
    <>
      <input
        ref={ref}
        id="frontElevationUpload"
        type="file"
        accept=".jpg, .jpeg, .png"
        onChange={handleFileUpload}
        className="hidden"
      />
      <button onClick={handleFileClick} className="border-blue rounded-[12px] border-2 bg-white p-2">
        <MdOutlineUploadFile size={20} />
      </button>
    </>
  );
};

export const FileRotate = ({
  name,
  file,
  jobReport,
  refetchJobReport,
  callback,
}: {
  name: string;
  file?: string;
  jobReport?: JobReport;
  refetchJobReport: () => void;
  callback: ({ id, data }: { id?: string; data: { value: string } }) => Promise<void | AxiosResponse<unknown, unknown>>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileRotate = useCallback(async () => {
    try {
      setLoading(true);
      if (!file) throw Error;

      const rotatedImage = await rotateBase64Image(file);
      await callback({
        id: jobReport?.id,
        data: { value: rotatedImage },
      });
      toast.success(`${name} rotated successfully`, { toastId: `${name}-success` });
      refetchJobReport();
    } catch (error) {
      handleUnknownDetail(error);
    } finally {
      setLoading(false);
    }
  }, [callback, file, jobReport?.id, name, refetchJobReport]);

  return (
    <button
      type="button"
      disabled={loading}
      onClick={handleFileRotate}
      className="border-blue flex size-10 items-center justify-center rounded-[12px] border-2 bg-white p-2"
    >
      {loading ? <ClipLoader size={14} color="#2a9c8e" /> : <MdOutlineCropRotate size={20} />}
    </button>
  );
};

export const FileDelete = ({
  name,
  jobReport,
  refetchJobReport,
  onDelete,
  onImageDelete,
}: {
  name: string;
  jobReport?: JobReport;
  refetchJobReport: () => void;
  onDelete?: ({ id }: { id?: string }) => Promise<void | AxiosResponse<unknown, unknown>>;
  onImageDelete: () => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileDelete = useCallback(async () => {
    if (!onDelete) return;
    try {
      setLoading(true);

      await onDelete({
        id: jobReport?.id,
      });
      toast.success(`${name} deleted successfully`, { toastId: `${name}-success` });
      onImageDelete();
      refetchJobReport();
    } catch (error) {
      handleUnknownDetail(error);
    } finally {
      setLoading(false);
    }
  }, [onDelete, onImageDelete, jobReport?.id, name, refetchJobReport]);

  return (
    <button
      type="button"
      disabled={loading}
      onClick={handleFileDelete}
      className="border-blue flex size-10 items-center justify-center rounded-[12px] border-2 bg-white p-2"
    >
      {loading ? <ClipLoader size={14} color="#2a9c8e" /> : <MdDeleteOutline size={20} />}
    </button>
  );
};

export const PropertyOverviewImage = ({
  savingReport,
  title,
  image,
  callback,
  className,
  jobReport,
  refetchJobReport,
  onDelete,
  showDeleteButton,
}: {
  savingReport: boolean;
  title: string;
  image?: string;
  callback: ({ id, data }: { id?: string; data: { value: string } }) => Promise<void | AxiosResponse<unknown, unknown>>;
  className: string;
  jobReport?: JobReport;
  refetchJobReport: KeyedMutator<JobReport>;
  onDelete?: ({ id }: { id?: string }) => Promise<void | AxiosResponse<unknown, unknown>>;
  showDeleteButton?: boolean;
}) => {
  const [file, setFile] = useState<string>();

  useEffect(() => {
    if (image) {
      setFile(`data:image/jpeg;base64,${image}`);
    }
  }, [image]);

  const handleImageDelete = () => {
    setFile(undefined);
  };

  return (
    <div className={className}>
      {file && <img src={file} alt={title} className="size-full object-cover" />}
      {!savingReport && (
        <div className="absolute bottom-2 right-2 flex flex-col gap-2">
          <FileUpload name={title} jobReport={jobReport} refetchJobReport={refetchJobReport} callback={callback} />
          <FileRotate
            name={title}
            file={file}
            jobReport={jobReport}
            refetchJobReport={refetchJobReport}
            callback={callback}
          />
          {showDeleteButton && (
            <FileDelete
              name={title}
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
              onDelete={onDelete}
              onImageDelete={handleImageDelete}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const TextField = ({
  name,
  title,
  placeholder,
  savingReport,
  defaultValue,
  callback,
  textCenter = false,
  className,
  jobReport,
  refetchJobReport,
}: {
  name: string;
  title: string;
  placeholder: string;
  savingReport: boolean;
  defaultValue?: string;
  callback: ({ id, data }: { id?: string; data: { value: string } }) => Promise<void | AxiosResponse<unknown, unknown>>;
  textCenter?: boolean;
  className: string;
  jobReport?: JobReport;
  refetchJobReport: KeyedMutator<JobReport>;
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [showSavePrompt, setShowSavePrompt] = useState<boolean>(false);
  const [textChanged, setTextChanged] = useState<boolean>(false);
  const [initialValue, setInitialValue] = useState<string>('');
  const formRef = useRef<HTMLFormElement>(null);
  const [cachedEvent, setCachedEvent] = useState<React.FocusEvent<HTMLFormElement> | null>(null);

  const defaultValues: { value: string } = {
    value: defaultValue || '',
  };

  const { register, handleSubmit, setValue, getValues } = useForm<{ value: string }>({
    defaultValues,
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    defaultValue && setValue('value', defaultValue);
  }, [defaultValue, setValue]);

  const onSubmit: SubmitHandler<{ value: string }> = async (data) => {
    try {
      await callback({ id: jobReport?.id, data: { value: data.value } });
      await refetchJobReport();
      toast.success(`${name} saved successfully`, { toastId: `${name}-success` });
      setEdit(false);
      setShowSavePrompt(false);
      setTextChanged(false);
      setInitialValue(data.value);
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLFormElement>) => {
    //If focus is outside of current item, then show the prompt
    if (
      !event.relatedTarget ||
      (formRef.current && event.relatedTarget && !formRef.current.contains(event.relatedTarget as Node))
    ) {
      //Store the event for use after the prompt is handled
      setCachedEvent(event);

      showSavePromptIfChanged();
      return;
    }
  };

  const showSavePromptIfChanged = () => {
    //Only show prompt if the text has changed
    if (textChanged) {
      setShowSavePrompt(true);
    } else {
      setEdit(false);
      setTextChanged(false);
    }
  };

  const exitEditMode = async () => {
    setValue('value', initialValue);
    setEdit(false);
  };

  const closeSavePrompt = async () => {
    //Modal discard button clicked
    setValue('value', initialValue);
    setEdit(false);
    setShowSavePrompt(false);
    setTextChanged(false);

    //Simulate the click the user made in order to continue process
    simulateClick(cachedEvent);
  };

  const saveChanges = async () => {
    //Modal save button clicked
    try {
      await handleSubmit(onSubmit)();
      setEdit(false);
      setShowSavePrompt(false);
      setTextChanged(false);

      //Simulate the click the user made to continue the process
      simulateClick(cachedEvent);
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  };

  const simulateClick = (event: React.FocusEvent<HTMLFormElement> | null) => {
    if (!event || !event.relatedTarget) {
      return;
    }

    const elementToClick = event.relatedTarget as HTMLElement;
    if (elementToClick) {
      elementToClick.click();
    }
  };

  useEffect(() => {
    const initialVal = getValues('value') || '';
    setInitialValue(initialVal);
  }, [getValues]);

  if (edit) {
    return (
      <>
        {showSavePrompt && textChanged && (
          <Modal onClose={closeSavePrompt}>
            <>
              <h1>Save Changes?</h1>
              <p className="p-2">It looks like you have unsaved changes. Would you like to save these?</p>
              <div className="flex flex-row gap-4">
                <Button style="primary" onClick={saveChanges}>
                  Save
                </Button>
                <Button style="secondary" onClick={closeSavePrompt}>
                  Discard
                </Button>
              </div>
            </>
          </Modal>
        )}
        <form onSubmit={handleSubmit(onSubmit)} onBlur={handleBlur} className={className} ref={formRef}>
          <textarea
            {...register('value')}
            id="value"
            onChange={() => setTextChanged(true)}
            name="value"
            title={title}
            autoFocus
            placeholder={placeholder}
            className="size-full resize-none rounded-[8px] p-2 pr-8 text-left font-medium outline-none"
          />
          <div className="absolute bottom-2.5 right-3 flex flex-row gap-1">
            <button type="button" onClick={() => exitEditMode()}>
              <MdClose size={20} className="text-error" />
            </button>
            <button type="submit">
              <MdCheck size={20} className="text-primary" />
            </button>
          </div>
        </form>
      </>
    );
  }

  return (
    <div className={className}>
      <p className={`${textCenter && 'text-center'} whitespace-pre-line p-2 text-sm font-medium`}>
        {defaultValue && isJSONString(defaultValue) ? JSON.parse(defaultValue) : defaultValue ? defaultValue : ''}
      </p>
      {!savingReport && (
        <button type="button" onClick={() => setEdit(true)} className="absolute bottom-0 right-0 cursor-pointer p-2">
          <MdOutlineDriveFileRenameOutline size={24} />
        </button>
      )}
    </div>
  );
};
