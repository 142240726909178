import React, { useEffect, useState } from 'react';
import { MdOutlineDarkMode, MdOutlineLightMode } from 'react-icons/md';
import { useTheme } from '../context/themeContext';
import useMediaMaxWidth from '../hooks/useMediaMaxWidth';

const Toggle = ({
  active,
  onClick,
  id,
  icon,
  customToggleColor,
}: {
  active: boolean;
  onClick: () => void;
  id?: string;
  icon?: JSX.Element;
  customToggleColor?: string;
}) => {
  return (
    <button
      type="button"
      id={id}
      className="border-primary bg-primary relative flex h-8 w-12 cursor-pointer flex-row items-center rounded-3xl border-2 px-1 lg:h-10 lg:w-16"
      onClick={onClick}
    >
      <div
        className={`absolute top-0.5 flex size-6 items-center justify-center rounded-full lg:size-8 ${
          active ? `left-4 lg:left-6 ${customToggleColor || 'primary'}` : `left-1 ${customToggleColor || 'neutral'}`
        }`}
      >
        {icon}
      </div>
    </button>
  );
};

export const ThemeToggle = () => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();
  const active = theme === 'dark';
  const isMobile = useMediaMaxWidth(1023);
  const iconSize = isMobile ? 16 : 20;

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return (
      <div className="border-neutral relative flex h-8 w-12 cursor-pointer flex-row items-center rounded-3xl border-2 px-1 shadow-inner lg:h-10 lg:w-16" />
    );
  }

  return (
    <Toggle
      id="theme-toggle"
      active={active}
      onClick={active ? () => setTheme('light') : () => setTheme('dark')}
      icon={
        active ? (
          <MdOutlineDarkMode className="text-primary" size={iconSize} />
        ) : (
          <MdOutlineLightMode className="text-primary" size={iconSize} />
        )
      }
      customToggleColor={active ? 'bg-light' : 'bg-white'}
    />
  );
};

export default Toggle;
