import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { MdKeyboardArrowLeft, MdOutlineDriveFileRenameOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Checkbox, FullPage, Loading, TextInput } from '@epcbuilder/lib/components';
import { Absence, AbsenceFormData, WorkingDayForm } from '@epcbuilder/lib/models/assessors';
import { Booking } from '@epcbuilder/lib/models/booking';
import { addMonths } from 'date-fns';
import useAbsence from '@/hooks/absences/useAbsence';
import useAssessor from '@/hooks/assessors/useAssessor';
import useAssessorSurveyBooking from '@/hooks/surveys/useAssessorSurveyBooking';
import { deleteAbsence, patchAbsence, patchAssessor, putAbsence, putWorkingHours } from '@/network/assessors';
import { groupWorkingDays } from '@/utils/timeUtils';
import { transformDefaultValues } from '@/utils/transformAbsenceValues';
import AbsenceFormModal from '../absence/modal/AbsenceFormModal';
import DeleteAbsenceModal from '../absence/modal/DeleteAbsenceModal';
import AssessmentCalendar from './bookings/AssessmentCalendar';
import UpcomingAssessments from './bookings/UpcomingAssessments';
import EditAvailabilityModal from './modals/EditAvailabityModal';

const AssessorProfile = ({ id }: { id: string }) => {
  const FIRST_DATE = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }, []);
  const LAST_DATE = useMemo(() => addMonths(FIRST_DATE, 3), [FIRST_DATE]);
  const END_DATE = useMemo(() => addMonths(FIRST_DATE, 3), [FIRST_DATE]);
  const [start, setStart] = useState(FIRST_DATE);

  useEffect(() => {
    setStart(FIRST_DATE);
  }, [FIRST_DATE, LAST_DATE]);

  const { mutate: refetchAbsence } = useAbsence({
    id,
    start,
    end: END_DATE,
  });
  const { bookings } = useAssessorSurveyBooking({ id, start, end: END_DATE });
  const { assessor, mutate: refetchAssessor, isLoading } = useAssessor({ id });
  const [showEditAvailabilityModal, setShowEditAvailabilityModal] = useState<boolean>(false);
  const [showAddAbsenceFormModal, setShowAddAbsenceFormModal] = useState<boolean>(false);
  const [showEditAbsenceFormModal, setShowEditAbsenceFormModal] = useState<boolean>(false);
  const [showDeleteAbsenceFormModal, setShowDeleteAbsenceFormModal] = useState<boolean>(false);
  const [selectedAbsence, setSelectedAbsence] = useState<Absence>();
  const [defaultValues, setDefaultValues] = useState<AbsenceFormData>();
  const [selectedBooking, setSelectedBooking] = useState<Booking>();
  const [transformedDefaultValues, setTransformedDefaultValues] = useState<AbsenceFormData>();

  if (isLoading || !assessor) {
    return (
      <FullPage>
        <Loading />
      </FullPage>
    );
  }

  const groupWorkingString = groupWorkingDays(assessor.workingDays);

  const handleWorkingHoursSubmit: SubmitHandler<WorkingDayForm[]> = async (data) => {
    await putWorkingHours(data, id);
    await refetchAssessor();
  };

  const handleCheckboxChange = async () => {
    const updatedAssessor = { ...assessor, active: !assessor.active };
    await patchAssessor(updatedAssessor);
    await refetchAssessor();
  };

  const handleAbsenceSelect = (absence: Absence) => {
    if (absence) {
      setSelectedAbsence(absence);

      const transformedValues = transformDefaultValues(absence);
      setTransformedDefaultValues(transformedValues);
      setShowEditAbsenceFormModal(true);
    }
  };

  const handleAbsenceSubmit: SubmitHandler<AbsenceFormData> = async (data) => {
    await putAbsence(data, assessor.id);
    refetchAbsence();
  };

  const handleAbsenceEditSubmit: SubmitHandler<AbsenceFormData> = async (data) => {
    if (selectedAbsence) {
      await patchAbsence(data, assessor.id, selectedAbsence.id);
    }
    await refetchAbsence();
  };

  const handleSlotClick = (date: Date, slot: number) => {
    const timeSlot = slot === 1 ? 'am' : 'pm';

    const absenceFormDefaultValues: AbsenceFormData = {
      absenceDateStart: date,
      absenceDateEnd: date,
      slotStart: timeSlot,
      slotEnd: timeSlot,
      absenceTypeId: '',
      notes: '',
    };

    setDefaultValues(absenceFormDefaultValues);
    setShowAddAbsenceFormModal(true);
  };

  const handleDelete = async () => {
    if (selectedAbsence) {
      await deleteAbsence(selectedAbsence.id);
    }
    refetchAbsence();
  };

  const handleBookingSelect = (booking: Booking | undefined) => {
    setSelectedBooking(booking);
  };

  return (
    <>
      <div className="flex flex-col p-4 sm:p-8">
        <div className="flex flex-row items-center justify-between">
          <Link id="back-to-all-assessors-link" to="/assessors" className="flex flex-row justify-start gap-2">
            <MdKeyboardArrowLeft size={20} /> Back to All Assessors
          </Link>
          <p id="assessor-profile-heading" className="mb-4 flex flex-1 justify-center p-4 pb-0 text-xl font-bold">
            ASSESSOR PROFILE
          </p>
          <div id="show-active-checkbox" className="justify-end">
            <Checkbox
              id="active-checkbox"
              label="Active Profile"
              checked={assessor.active}
              onChange={handleCheckboxChange}
            />
          </div>
        </div>
        <div className="mt-8 flex flex-row gap-8 px-8">
          <div className="w-full">
            <p className="ml-4 font-bold">Name</p>
            <TextInput
              readOnly
              id="fullName"
              name="fullName"
              overrideBaseClassnames
              className="text-color dark:bg-dark border-blue h-10 w-full rounded-xl border-2 px-4 text-base outline-none hover:cursor-default"
              value={`${assessor.firstName} ${assessor.lastName}`}
            />
          </div>
          <div className="w-full">
            <p className="ml-4 font-bold">Email</p>
            <TextInput
              readOnly
              id="email"
              name="email"
              overrideBaseClassnames
              className="text-color dark:bg-dark border-blue h-10 w-full truncate rounded-xl border-2 px-4 text-base outline-none hover:cursor-default"
              value={assessor.email}
            />
          </div>
          <div className="w-full">
            <p className="ml-4 font-bold">Availability</p>
            <div className="relative flex flex-row gap-4">
              <TextInput
                readOnly
                id="availability"
                name="availability"
                overrideBaseClassnames
                className="text-color border-blue dark:bg-dark h-10 w-full truncate rounded-xl border-2 px-4 pr-10 outline-none hover:cursor-default"
                value={groupWorkingString}
              />
              <button
                id="availability-edit-button"
                className="flex items-center"
                onClick={() => {
                  setShowEditAvailabilityModal(true);
                }}
              >
                <MdOutlineDriveFileRenameOutline
                  size={20}
                  className="text-color absolute right-4 top-1/2 mr-4 -translate-y-1/2"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="m-auto flex flex-row">
          <div className="w-[40rem] lg:col-span-3 xl:col-span-7 xl:row-span-2">
            <AssessmentCalendar
              id={id}
              onAbsenceSelect={handleAbsenceSelect}
              onSlotClick={handleSlotClick}
              onBookingSelect={handleBookingSelect}
            />
          </div>
        </div>
        {bookings && bookings.length > 0 && (
          <UpcomingAssessments bookings={bookings} selectedBooking={selectedBooking} />
        )}
        {showEditAvailabilityModal && (
          <EditAvailabilityModal
            defaultValues={assessor.workingDays}
            onClose={() => {
              setShowEditAvailabilityModal(false);
            }}
            onSubmit={handleWorkingHoursSubmit}
          />
        )}
        {showAddAbsenceFormModal && (
          <AbsenceFormModal
            defaultValues={defaultValues as AbsenceFormData}
            onClose={() => {
              setShowAddAbsenceFormModal(false);
            }}
            title="Create Absence"
            successMessage="Absence Created Successfully"
            onSubmit={handleAbsenceSubmit}
            booking={bookings}
          />
        )}
        {showEditAbsenceFormModal && transformedDefaultValues && (
          <AbsenceFormModal
            defaultValues={transformedDefaultValues}
            onClose={() => {
              setShowEditAbsenceFormModal(false);
            }}
            title="Edit Absence"
            successMessage="Absence Edited Successfully"
            onSubmit={handleAbsenceEditSubmit}
            handleDelete={() => {
              setShowDeleteAbsenceFormModal(true);
              setShowEditAbsenceFormModal(false);
            }}
            booking={bookings}
          />
        )}
        {showDeleteAbsenceFormModal && selectedAbsence && (
          <DeleteAbsenceModal
            value={selectedAbsence}
            onClose={() => {
              setShowDeleteAbsenceFormModal(false);
            }}
            callback={handleDelete}
          />
        )}
      </div>
    </>
  );
};

export default AssessorProfile;
