import React, { useCallback, useEffect, useState } from 'react';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Container, FullPage, Loading, Modal } from '@epcbuilder/lib/components';
import { capitalize } from '@epcbuilder/lib/utils';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import useInstallationStatuses from '@/hooks/installations/useInstallationStatuses';
import useJobNotes from '@/hooks/jobs/useJobNotes';
import useProperty from '@/hooks/properties/useProperty';
import usePropertyCurrentJob from '@/hooks/properties/usePropertyCurrentJob';
import useSurveyStatuses from '@/hooks/surveys/useSurveyStatuses';
import { postJobExpire } from '@/network/jobs';
import AddNoteToJobModal from './modals/AddNoteToJobModal';
import AdminContactEmailModal from './modals/AdminContactEmailModal';
import JobDetailsData from './JobDetailsData';
import JobInstallationData from './JobInstallationData';
import JobNotesData from './JobNotesData';
import JobSurveyData from './JobSurveyData';
import JobUserData from './JobUserData';
import PropertyAccessData from './PropertyAccessData';
import PropertyAddressData from './PropertyAddressData';
import PropertyRatingData from './PropertyRatingData';
import PropertyStatus from './PropertyStatusForm';

enum PropertyTabs {
  PROPERTY = 'property',
  JOB = 'job',
  NOTES = 'notes',
}

const Property = ({ id }: { id: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { property, mutate: refetchProperty, error, isLoading } = useProperty({ id });
  const { job, mutate: refetchJob } = usePropertyCurrentJob({ id: property?.id });
  const { surveyStatuses } = useSurveyStatuses();
  const { installationStatuses } = useInstallationStatuses();
  const { jobNotes, mutate: refetchJobNotes } = useJobNotes({ id: job?.id });

  const [active, setActive] = useState<PropertyTabs>(PropertyTabs.PROPERTY);
  const [showExpireJobModal, setShowExpireJobModal] = useState<boolean>(false);
  const [showContactModal, setShowAdminContactEmailModal] = useState<boolean>(false);
  const [showAddNoteModal, setAddNoteModal] = useState<boolean>(false);

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (!tab) return;

    setActive(tab as PropertyTabs);
  }, [searchParams]);

  useEffect(() => {
    if (error) {
      const { detail } = error;
      if (detail === "Property doesn't exist." || detail === 'Property is deleted.') {
        toast.error('This property does not exist, please go back and try again.');
      }
    }
  }, [error]);

  const handleExpireJob = useCallback(async () => {
    try {
      await postJobExpire({ id: job?.id });
      await refetchJob();
      toast.success('Job successfully expired');
      setShowExpireJobModal(false);
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  }, [job, refetchJob]);

  if (isLoading || !property || !surveyStatuses || !installationStatuses) {
    return (
      <FullPage>
        <Loading />
      </FullPage>
    );
  }

  return (
    <>
      <div className="flex flex-col p-4 sm:p-8">
        <div className="flex flex-row items-center gap-8">
          <Link to="/properties" className="flex flex-row items-center gap-2">
            <MdKeyboardArrowLeft size={20} /> Back
          </Link>
          <p className="text-xl">{`${property?.addressLine1}, ${property?.addressLine2}`}</p>
        </div>
        <Container>
          <div className="flex flex-row">
            <button
              type="button"
              id="property-tab"
              onClick={() => setSearchParams({ tab: PropertyTabs.PROPERTY })}
              className={`${
                active === PropertyTabs.PROPERTY ? 'bg-primary border-primary text-light' : 'border-neutral'
              }  h-10 flex-1 rounded-l-[16px] border-2`}
            >
              <p id={active === PropertyTabs.PROPERTY ? 'active-tab' : undefined} className="text-center">
                {capitalize(PropertyTabs.PROPERTY)}
              </p>
            </button>
            <button
              type="button"
              id="job-tab"
              onClick={() => setSearchParams({ tab: PropertyTabs.JOB })}
              className={`${
                active === PropertyTabs.JOB ? 'bg-primary border-primary text-light' : 'border-neutral'
              }  h-10 flex-1 border-y-2`}
            >
              <p id={active === PropertyTabs.JOB ? 'active-tab' : undefined} className="text-center">
                {capitalize(PropertyTabs.JOB)}
              </p>
            </button>
            <button
              type="button"
              id="notes-tab"
              onClick={() => setSearchParams({ tab: PropertyTabs.NOTES })}
              className={`${
                active === PropertyTabs.NOTES ? 'bg-primary border-primary text-light' : 'border-neutral'
              }  h-10 flex-1 rounded-r-[16px] border-2`}
            >
              <p id={active === PropertyTabs.NOTES ? 'active-tab' : undefined} className="text-center">
                {capitalize(PropertyTabs.NOTES)}
              </p>
            </button>
          </div>
          {active == PropertyTabs.PROPERTY && (
            <div className="mt-4 flex flex-col gap-4">
              <PropertyStatus id={id} status={property?.status} />
              <PropertyAddressData property={property} mutate={refetchProperty} />
              <PropertyAccessData property={property} mutate={refetchProperty} />
              <PropertyRatingData id={id} />
            </div>
          )}
          {active === PropertyTabs.JOB && (
            <div className="mt-4 flex flex-col gap-4">
              <div className="flex justify-between">
                <div className="w-fit">
                  <Button id="contact-button" style="secondary" onClick={() => setShowAdminContactEmailModal(true)}>
                    Contact
                  </Button>
                </div>
                <div className="w-fit">
                  <Button id="expire-job-button" style="secondary" onClick={() => setShowExpireJobModal(true)}>
                    Expire job
                  </Button>
                </div>
              </div>
              {job?.eligible && (
                <div className="text-dark flex rounded-xl bg-[#D3E9D5]">
                  <IoMdCheckmarkCircleOutline
                    size={45}
                    id="checkMarkIcon"
                    className="ml-[-20px] mt-1 rounded-full bg-[#5AB463] p-1 text-[#E5F1E7]"
                  />
                  <p className="p-4">This customer has qualified for funding</p>
                </div>
              )}
              <JobUserData id={job?.id} />
              <JobDetailsData job={job} refetchJob={refetchJob} />
              <JobSurveyData job={job} refetchJob={refetchJob} surveyStatuses={surveyStatuses} />
              <JobInstallationData job={job} refetchJob={refetchJob} installationStatuses={installationStatuses} />
            </div>
          )}
          {active === PropertyTabs.NOTES && (
            <div className="mt-4 flex flex-col gap-4">
              <div className="flex justify-end">
                <div className="w-fit">
                  <Button style="secondary" onClick={() => setAddNoteModal(true)}>
                    Add new note
                  </Button>
                </div>
              </div>
              <JobNotesData notes={jobNotes} refetch={refetchJobNotes} />
            </div>
          )}
        </Container>
      </div>
      {showContactModal && (
        <AdminContactEmailModal
          id={job!.id}
          job={job!}
          property={property!}
          onClose={() => {
            setShowAdminContactEmailModal(false);
            refetchJob();
          }}
        />
      )}
      {showExpireJobModal && (
        <Modal onClose={() => setShowExpireJobModal(false)}>
          <div className="flex flex-col gap-2">
            <p className="text-xl">Are you sure you want to expire this job?</p>
            <div className="flex flex-row gap-2">
              <Button style="secondary" onClick={() => setShowExpireJobModal(false)}>
                Cancel
              </Button>
              <Button style="delete" onClick={handleExpireJob}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {showAddNoteModal && (
        <AddNoteToJobModal
          job={job!}
          onClose={() => {
            setAddNoteModal(false);
            refetchJobNotes();
          }}
        />
      )}
    </>
  );
};

export default Property;
