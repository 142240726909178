import React, { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Checkbox, SensitiveTextInput, TextInput } from '@epcbuilder/lib/components';
import { AuthContext } from '@epcbuilder/lib/context/authContext';
import { Login } from '@epcbuilder/lib/models/auth';
import { Token } from '@epcbuilder/lib/models/authContext';
import { EMAIL_REGEX, handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as jose from 'jose';
import * as yup from 'yup';
import { postLogin } from '@/network/auth';

const loginSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  password: yup.string().required('Password must not be empty'),
  rememberMe: yup.bool().required(),
});

const LoginForm = () => {
  const [searchParams] = useSearchParams();
  const { dispatchLogin } = useContext(AuthContext);
  const navigate = useNavigate();

  const defaultValues: Login = {
    email: '',
    password: '',
    rememberMe: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    getValues,
  } = useForm<Login>({
    defaultValues,
    resolver: yupResolver(loginSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<Login> = async (data) => {
    try {
      const redirect = searchParams.get('redirect');
      const response = await postLogin({
        email: data.email.trim(),
        password: data.password,
        rememberMe: data.rememberMe,
      });
      if (!response) throw Error();
      const { token, refreshToken } = response.data;
      const payload = jose.decodeJwt(token) as Token;
      dispatchLogin({ token, refreshToken, rememberMe: getValues('rememberMe'), userId: payload.ID });
      toast.success('Login successful', { toastId: 'login-success' });
      navigate(redirect || '/properties');
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<Login>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  return (
    <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        {...register('email')}
        id="email"
        name="email"
        title="Your email address"
        placeholder="Email"
        error={errors.email?.message}
        autoComplete="username"
      />
      <SensitiveTextInput
        {...register('password')}
        id="password"
        name="password"
        title="Your password"
        placeholder="Password"
        error={errors.password?.message}
        autoComplete="current-password"
      />
      <div className="flex flex-row flex-wrap justify-between gap-2">
        <div id="remember-me-checkbox" className="flex flex-row items-center gap-2">
          <Checkbox id="rememberMe" {...register('rememberMe')} label="Remember me" labelclassname="text-white" />
        </div>
      </div>
      <Button id="login-submit" loading={isSubmitting} type="submit">
        Login
      </Button>
    </form>
  );
};

export default LoginForm;
