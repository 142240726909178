import React, { MutableRefObject, SetStateAction } from 'react';
import { MdClose } from 'react-icons/md';
import { KeyedMutator } from 'swr';
import { JobReport } from '@/models/job';
import {
  deleteReportPropertyOverviewFloor1Plan,
  deleteReportPropertyOverviewFloor2Plan,
  putReportPropertyOverviewFloor1Plan,
  putReportPropertyOverviewFloor2Plan,
} from '@/network/report';
import { getImageKey } from '@/utils/getImageKey';
import { PropertyOverviewImage } from '../helpers';
import { Bottom, Page, Top } from '../ReportLayout';
import { IFloorPlans } from '../ReportPDF';

const ReportPageExtraFloorPlans = ({
  refs,
  savingReport,
  jobReport,
  refetchJobReport,
  extraFloorPlans,
  setExtraFloorPlans,
  prevSlide,
}: {
  refs: MutableRefObject<(HTMLDivElement | null)[]>;
  savingReport: boolean;
  jobReport?: JobReport;
  refetchJobReport: KeyedMutator<JobReport>;
  extraFloorPlans: IFloorPlans;
  setExtraFloorPlans: React.Dispatch<SetStateAction<IFloorPlans>>;
  prevSlide: () => void;
}) => {
  const imageKeyFirstFloor = getImageKey(extraFloorPlans.floor1Plan);
  const imageKeySecondFloor = getImageKey(extraFloorPlans.floor2Plan);

  const handleDeleteFloor1Plan = async ({ id, clearOnly = false }: { id?: string; clearOnly?: boolean }) => {
    if (clearOnly) {
      setExtraFloorPlans((current) => ({
        ...current,
        floor1Plan: { ...current.floor1Plan, updating: true },
      }));

      if (extraFloorPlans.floor1Plan.image) {
        await deleteReportPropertyOverviewFloor1Plan({ id: id });

        setExtraFloorPlans((current) => ({ ...current, floor1Plan: { ...current.floor1Plan, image: undefined } }));
      }

      setExtraFloorPlans((current) => ({
        ...current,
        floor1Plan: { ...current.floor1Plan, updating: false, show: true },
      }));
    }

    if (!clearOnly) {
      setExtraFloorPlans((current) => ({
        floor1Plan: { ...current.floor1Plan, updating: true },
        floor2Plan: { ...current.floor2Plan, updating: true },
      }));

      if (extraFloorPlans.floor2Plan.image) {
        await putReportPropertyOverviewFloor1Plan({
          id: id,
          data: { value: `data:image/jpeg;base64,${extraFloorPlans.floor2Plan.image}` },
        });

        await deleteReportPropertyOverviewFloor2Plan({ id: id });

        setExtraFloorPlans((current) => ({
          floor1Plan: { ...current.floor1Plan, image: current.floor2Plan.image },
          floor2Plan: { ...current.floor2Plan, image: undefined },
        }));
      }

      if (!extraFloorPlans.floor2Plan.image && extraFloorPlans.floor1Plan.image) {
        await deleteReportPropertyOverviewFloor1Plan({ id: id });

        setExtraFloorPlans((current) => ({
          ...current,
          floor1Plan: { ...current.floor1Plan, image: undefined },
        }));
      }

      setExtraFloorPlans((current) => ({
        floor1Plan: { ...current.floor1Plan, updating: false, show: true },
        floor2Plan: { ...current.floor2Plan, updating: false, show: false },
      }));

      prevSlide();
    }
  };

  const handleDeleteFloor2Plan = async ({ id, clearOnly = false }: { id?: string; clearOnly?: boolean }) => {
    setExtraFloorPlans((current) => ({
      ...current,
      floor2Plan: { ...current.floor2Plan, updating: true },
    }));

    if (extraFloorPlans.floor2Plan.image) {
      await deleteReportPropertyOverviewFloor2Plan({ id: id });

      setExtraFloorPlans((current) => ({ ...current, floor2Plan: { ...current.floor2Plan, image: undefined } }));
    }

    setExtraFloorPlans((current) => ({
      ...current,
      floor2Plan: { ...current.floor2Plan, updating: false, show: clearOnly },
    }));

    if (!clearOnly) {
      prevSlide();
    }
  };

  return (
    <Page refs={refs} index={3}>
      <Top>
        <h1 className="text-primary font-sans text-3xl font-black uppercase">Property Overview</h1>
      </Top>
      <div className="mx-[64px] mt-[110px] flex flex-col gap-8">
        <div className="relative-container">
          {!savingReport && (
            <div className="absolute-top flex flex-row justify-end" style={{ position: 'absolute', right: '75px' }}>
              <button
                type="button"
                onClick={async () => {
                  await handleDeleteFloor1Plan({ id: jobReport?.id });
                }}
                className={`text-error cursor-pointer`}
                title="Remove Floor Plan"
                disabled={extraFloorPlans.floor1Plan.updating}
              >
                <MdClose size={22} />
              </button>
            </div>
          )}
          <div className="flex flex-1 flex-col gap-2">
            <p className="ml-4 text-lg font-bold">First Floor</p>
            <div className="bg-light-dark h-[260px] w-full rounded-[16px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
              <PropertyOverviewImage
                key={`FirstFloor-${imageKeyFirstFloor}`}
                savingReport={savingReport}
                title="First Floor"
                image={extraFloorPlans.floor1Plan.image}
                callback={putReportPropertyOverviewFloor1Plan}
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
                className="bg-blue-light relative size-full overflow-hidden rounded-[12px]"
                onDelete={({ id }) => handleDeleteFloor1Plan({ id: id, clearOnly: true })}
                showDeleteButton={Boolean(extraFloorPlans.floor1Plan.image)}
              />
            </div>
          </div>
        </div>
        <div className="relative-container">
          {!savingReport && (
            <div className="absolute-top flex flex-row justify-end" style={{ position: 'absolute', right: '75px' }}>
              <button
                type="button"
                onClick={async () => {
                  await handleDeleteFloor2Plan({ id: jobReport?.id });
                }}
                className={`text-error cursor-pointer`}
                title="Remove Floor Plan"
                disabled={extraFloorPlans.floor2Plan.updating}
              >
                <MdClose size={22} />
              </button>
            </div>
          )}
          <div className="flex flex-1 flex-col gap-2">
            <p className="ml-4 text-lg font-bold">Second Floor</p>
            <div className="bg-light-dark h-[260px] w-full rounded-[16px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
              <PropertyOverviewImage
                key={`SecondFloor-${imageKeySecondFloor}`}
                savingReport={savingReport}
                title="Second Floor"
                image={extraFloorPlans.floor2Plan.image}
                callback={putReportPropertyOverviewFloor2Plan}
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
                className="bg-blue-light relative size-full overflow-hidden rounded-[12px]"
                onDelete={({ id }) => handleDeleteFloor2Plan({ id, clearOnly: true })}
                showDeleteButton={Boolean(extraFloorPlans.floor2Plan.image)}
              />
            </div>
          </div>
        </div>

        <Bottom title="Property Overview" />
      </div>
    </Page>
  );
};

export default ReportPageExtraFloorPlans;
