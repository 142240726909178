import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdOutlineAlarm } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Loading, Modal, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { WorkingDay, WorkingDayForm } from '@epcbuilder/lib/models/assessors';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { DaysOfWeek } from '@epcbuilder/lib/utils/datetime';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const timeRegex = /^(?:([01]\d|2[0-3]):([0-5]\d)|)$/;

const workingDaySchema = yup.object().shape({
  startTime: yup
    .string()
    .matches(timeRegex, 'Invalid time format')
    .test('is-before-end', 'Start time must be before end time', function (value) {
      const { endTime } = this.parent;
      return !value || !endTime || value < endTime;
    }),
  endTime: yup
    .string()
    .matches(timeRegex, 'Invalid time format')
    .test('is-after-start', 'End time must be after start time', function (value) {
      const { startTime } = this.parent;
      return !value || !startTime || value > startTime;
    }),
});

const formSchema = yup.object().shape({
  workingDays: yup.array().of(workingDaySchema).required(),
});

const EditAvailabilityModal = ({
  onSubmit,
  defaultValues,
  onClose,
}: {
  defaultValues: WorkingDay[];
  onClose: () => void;
  onSubmit: (data: WorkingDayForm[]) => void;
}) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    register,
    setValue,
    clearErrors,
  } = useForm<{ workingDays: WorkingDayForm[] }>({
    defaultValues: { workingDays: defaultValues },
    resolver: yupResolver(formSchema),
    reValidateMode: 'onSubmit',
  });

  const handleFormSubmit: SubmitHandler<{ workingDays: WorkingDayForm[] }> = async (data) => {
    try {
      const updatedData = data.workingDays.map((workingDay, index) => ({
        ...workingDay,
        dayName: DaysOfWeek[index],
      }));

      onSubmit(updatedData);
      toast.success('Availability updated successfully.', { toastId: 'edit-availability-success' });
      onClose();
    } catch (error: unknown) {
      if ((error as AxiosErrorData).errors) {
        const { errors } = error as AxiosErrorData;
        handleFormErrors(setError, errors);
        handleUnknownDetail(error);
      }
    }
  };

  const formatTime = (value: string) => {
    const cleaned = value.replace(/\D+/g, '');
    const truncated = cleaned.substring(0, 4);
    const match = truncated.match(/^(\d{0,2})(\d{0,2})$/);
    if (match) {
      const part1 = match[1];
      const part2 = match[2];
      if (part2) {
        return `${part1}:${part2}`.substring(0, 5);
      }
      return part1;
    }
    return value;
  };

  useEffect(() => {
    DaysOfWeek.forEach((day, index) => {
      const dayValue = defaultValues.find((d) => d.dayName === day);
      setValue(`workingDays.${index}.startTime`, dayValue ? dayValue.startTime.slice(0, 5) : '');
      setValue(`workingDays.${index}.endTime`, dayValue ? dayValue.endTime.slice(0, 5) : '');
    });
  }, [defaultValues, setValue]);

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="availability-modal" onClose={onClose}>
      <h1 className="font-header text-2xl">Edit Availability</h1>
      <form className="mt-4 flex flex-col">
        {DaysOfWeek.map((day, index) => (
          <div key={day} className="mt-3 flex flex-row gap-6">
            <div className="bg-blue dark:bg-primary-darker text-color flex h-10 w-[6rem] cursor-default items-center justify-center rounded-xl px-3 font-bold">
              {day.substring(0, 3).toUpperCase()}
            </div>
            <div className="flex flex-row gap-6">
              <TextInput
                {...register(`workingDays.${index}.startTime`)}
                id={`${index}.startTime`}
                formatValue={formatTime}
                error={errors.workingDays?.[index]?.startTime?.message}
                iconRight={<MdOutlineAlarm size={20} />}
                callbackOnChange={() => {
                  clearErrors();
                }}
              />
              <TextInput
                {...register(`workingDays.${index}.endTime`)}
                id={`${index}.endTime`}
                formatValue={formatTime}
                error={errors.workingDays?.[index]?.endTime?.message}
                iconRight={<MdOutlineAlarm size={20} />}
                callbackOnChange={() => {
                  clearErrors();
                }}
              />
            </div>
          </div>
        ))}
        <div className="mt-6 flex justify-center gap-4">
          <NewButton id="delete-cancel" variant="secondary" text="Cancel" onClick={onClose} />
          <NewButton
            id="availability-submit"
            variant="primary"
            text="Save Changes"
            onClick={handleSubmit(handleFormSubmit)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default EditAvailabilityModal;
