import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { NewSelect } from '@epcbuilder/lib/components';

const absenceSlotOptions = [
  { value: 'am', label: 'AM' },
  { value: 'pm', label: 'PM' },
];

const SelectAbsenceSlot = <T extends FieldValues>({
  control,
  error,
  id,
  availableSlots,
  bookingError,
}: {
  control: Control<T>;
  error?: string;
  id: string;
  availableSlots: { am: boolean; pm: boolean };
  bookingError?: boolean;
}) => {
  const filteredOptions = absenceSlotOptions.filter(
    (option) => (option.value === 'am' && availableSlots.am) || (option.value === 'pm' && availableSlots.pm)
  );
  return (
    <div id="assessors-filters" className="flex flex-1 flex-col flex-wrap gap-2 sm:flex-row sm:items-center">
      <div className="w-full">
        <NewSelect
          className="rounded-xl"
          control={control}
          id={id}
          name={`${id}` as Path<T>}
          title="Absence slot"
          options={filteredOptions}
        />
      </div>
      {error && (
        <div id="absence-slot-error" className="ml-2 flex flex-col">
          <p className="text-error text-xs">{error}</p>
        </div>
      )}
      {bookingError && (
        <div id="absence-booking-error" className="ml-2 flex flex-col">
          <p className="text-error text-xs">Absence can't be booked during dates that already have a survey booking.</p>
        </div>
      )}
    </div>
  );
};
export default SelectAbsenceSlot;
