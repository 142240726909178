import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdOutlineControlPoint, MdOutlineSearch } from 'react-icons/md';
import { Button, Loading, TextInput } from '@epcbuilder/lib/components';
import Pagination from '@epcbuilder/lib/components/Pagination';
import { AssessorFormInput, Filters } from '@epcbuilder/lib/models/assessors';
import useAssessors from '@/hooks/assessors/useAssessors';
import { putAssessor } from '@/network/assessors';
import AssessorFormModal from './modals/AssessorFormModal';
import AssessorsHeader from './AssessorsHeader';
import AssessorsRow from './AssessorsRow';
import FilterAssessor from './FilterAssessor';

const AssessorsTable = () => {
  const defaultValues = {
    search: '',
    status: '',
    page: 1,
    limit: 10,
  };

  const assessorFormDefaultValues: AssessorFormInput = {
    firstName: '',
    lastName: '',
    email: '',
    officeAddress: '',
    officePostcode: '',
    phoneNumber: '',
  };

  const { register, watch, control, setValue } = useForm<Filters>({ defaultValues });
  const search = watch('search');
  const status = watch('status');
  const currentPage = watch('page') || 1;
  const limit = watch('limit') || 10;

  const { assessors, mutate, isLoading } = useAssessors();

  const [showCreateAssessorsModal, setShowCreateAssessorsModal] = useState<boolean>(false);

  useEffect(() => {
    setValue('page', 1);
  }, [limit, search, setValue]);

  const { filteredAssessors, filteredTotalCount } = useMemo(() => {
    if (!assessors) return { filteredAssessors: [], filteredTotalCount: 0 };

    const matchesSearchAndStatus = assessors.filter((assessor) => {
      const matchesSearch =
        assessor.firstName.toLowerCase().includes(search.toLowerCase()) ||
        assessor.lastName.toLowerCase().includes(search.toLowerCase()) ||
        assessor.email.toLowerCase().includes(search.toLowerCase()) ||
        `${assessor.firstName.toLowerCase()} ${assessor.lastName.toLowerCase()}`.includes(search.toLowerCase());

      const matchesStatus =
        status === '' || (status === 'active' && assessor.active) || (status === 'inactive' && !assessor.active);

      return matchesSearch && matchesStatus;
    });

    const filteredTotalCount = matchesSearchAndStatus.length;

    const startIndex = (currentPage - 1) * limit;
    const endIndex = startIndex + limit;

    return {
      filteredAssessors: matchesSearchAndStatus.slice(startIndex, endIndex),
      filteredTotalCount,
    };
  }, [assessors, search, status, currentPage, limit]);

  const totalPages = Math.ceil(filteredTotalCount / limit);

  const handleSubmit: SubmitHandler<AssessorFormInput> = async (data) => {
    await putAssessor(data);
  };

  return (
    <>
      <div className="flex w-96 flex-col gap-4 p-8">
        <p className="mb-4 flex flex-1 justify-start p-4 pb-0 text-xl font-bold">ASSESSORS</p>
        <div className="flex flex-1 flex-row items-center justify-start gap-4">
          <Button
            className="hover:border-primary-lighter flex w-60 flex-1 bg-white"
            style="secondary"
            id="add-new-button"
            onClick={() => setShowCreateAssessorsModal(true)}
          >
            <p className="text-color flex flex-row items-center gap-4">
              <MdOutlineControlPoint size={20} className="justify-left flex flex-1 flex-row items-center" /> Add
              Assessor
            </p>
          </Button>
          <div className="relative">
            <TextInput
              {...register('search')}
              id="search"
              name="search"
              placeholder="Search Assessor..."
              className="border-blue bg-color-white hover:border-primary-lighter focus:border-primary-lighter placeholder:text-color flex h-12 w-80 flex-1 flex-row items-center justify-center rounded-[24px] border-2 px-4 shadow outline-none"
              overrideBaseClassnames
            />
            <MdOutlineSearch size={20} className="text-color absolute right-4 top-1/2 -translate-y-1/2" />
          </div>
          <FilterAssessor control={control} />
        </div>
      </div>
      <AssessorsHeader />
      {isLoading || !filteredAssessors ? (
        <Loading id="assessors-loading" />
      ) : filteredAssessors.length === 0 ? (
        <p id="assessors-count-empty" className="my-8 flex items-center justify-center">
          There are no assessors
        </p>
      ) : (
        <div id="assessors-table" className="px-8 pb-8 pt-0">
          {filteredAssessors.map((assessor) => (
            <AssessorsRow key={assessor.id} assessor={assessor} mutate={mutate} />
          ))}
        </div>
      )}
      {showCreateAssessorsModal && (
        <AssessorFormModal
          defaultValues={assessorFormDefaultValues}
          onClose={() => {
            setShowCreateAssessorsModal(false);
            mutate();
          }}
          onSubmit={handleSubmit}
          title="Create Assessor"
          successMessage="Assessor successfully created."
        />
      )}
      {filteredTotalCount > 0 && (
        <div className="flex items-center justify-center p-4">
          <Pagination
            control={control}
            limit={limit}
            name="page"
            totalPages={totalPages}
            currentPage={currentPage}
            count={filteredTotalCount}
          />
        </div>
      )}
    </>
  );
};

export default AssessorsTable;
