import React, { MutableRefObject } from 'react';
import { MdAddCircleOutline, MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import { KeyedMutator } from 'swr';
import { JobReport } from '@/models/job';
import {
  putReportPropertyOverviewFrontElevation,
  putReportPropertyOverviewRearElevation,
  putReportPropertyOverviewSummary,
} from '@/network/report';
import FloorPlansOverview from './floor-plan/FloorPlan';
import { PropertyOverviewImage, TextField } from './helpers';
import { Bottom, Page, Top } from './ReportLayout';
import { IFloorPlans } from './ReportPDF';

const ReportPage3 = ({
  refs,
  savingReport,
  jobReport,
  refetchJobReport,
  onAddFloorPlan,
  onDeleteFloorPlan,
  extraFloorPlans,
  nextSlide,
}: {
  refs: MutableRefObject<(HTMLDivElement | null)[]>;
  savingReport: boolean;
  jobReport?: JobReport;
  refetchJobReport: KeyedMutator<JobReport>;
  onAddFloorPlan?: () => void;
  onDeleteFloorPlan?: () => Promise<void>;
  extraFloorPlans: IFloorPlans;
  nextSlide: () => void;
}) => {
  return (
    <Page refs={refs} index={2}>
      <Top>
        <h1 className="text-primary font-sans text-3xl font-black uppercase">Property Overview</h1>
      </Top>
      <div className="mx-16 mt-20 flex flex-col gap-4">
        <TextField
          name="Summary"
          title="Property Overview Summary"
          placeholder="Summary..."
          savingReport={savingReport}
          defaultValue={jobReport?.propertyOverview.summary}
          callback={putReportPropertyOverviewSummary}
          textCenter={true}
          className="bg-primary/10 relative flex h-16 items-center justify-center rounded-[12px]"
          jobReport={jobReport}
          refetchJobReport={refetchJobReport}
        />
        <div className="flex flex-row gap-4">
          <div className="flex flex-1 flex-col gap-2">
            <p className="ml-4 text-lg font-bold">Front Elevation</p>
            <div className="bg-light-dark h-[260px] w-full rounded-[16px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
              <PropertyOverviewImage
                savingReport={savingReport}
                title="Front Elevation"
                image={jobReport?.propertyOverview.frontelevation}
                callback={putReportPropertyOverviewFrontElevation}
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
                className="bg-blue-light relative size-full overflow-hidden rounded-[12px]"
              />
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-2">
            <p className="ml-4 text-lg font-bold">Back Elevation</p>
            <div className="bg-light-dark h-[260px] w-full rounded-[16px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
              <PropertyOverviewImage
                savingReport={savingReport}
                title="Back Elevation"
                image={jobReport?.propertyOverview.rearelevation}
                callback={putReportPropertyOverviewRearElevation}
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
                className="bg-blue-light relative size-full overflow-hidden rounded-[12px]"
              />
            </div>
          </div>
        </div>
        <div className="relative-container">
          {!savingReport && !extraFloorPlans.floor2Plan.show && (
            <div className="absolute-top flex flex-row justify-end" style={{ position: 'absolute', right: '75px' }}>
              <button
                type="button"
                onClick={onAddFloorPlan}
                className={`cursor-pointer ${extraFloorPlans.floor2Plan.show ? 'opacity-50' : ''}`}
                title="Add Floor Plan"
                disabled={extraFloorPlans.floor2Plan.show}
              >
                <MdAddCircleOutline size={22} />
              </button>
              {extraFloorPlans.floor1Plan.show && (
                <button
                  type="button"
                  onClick={async () => onDeleteFloorPlan && (await onDeleteFloorPlan())}
                  className={`cursor-pointer ${extraFloorPlans.floor2Plan.show ? 'opacity-50' : ''} text-error`}
                  title="Remove Floor Plan"
                  disabled={extraFloorPlans.floor2Plan.show}
                >
                  <MdClose size={22} />
                </button>
              )}
            </div>
          )}
          {!savingReport && extraFloorPlans.floor2Plan.show && (
            <div className="absolute-top flex flex-row justify-end" style={{ position: 'absolute', right: '75px' }}>
              <button type="button" onClick={nextSlide} className="cursor-pointer" title="View More Floor Plans">
                <MdKeyboardArrowRight size={22} />
              </button>
            </div>
          )}
          <FloorPlansOverview
            jobReport={jobReport}
            savingReport={savingReport}
            refetchJobReport={refetchJobReport}
            extraFloorPlans={extraFloorPlans}
          />
        </div>
      </div>
      <Bottom title="Property Overview" />
    </Page>
  );
};

export default ReportPage3;
