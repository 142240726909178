import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdCheck, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Select } from '@epcbuilder/lib/components';
import { Job, SurveyStatusStrings } from '@epcbuilder/lib/models/jobs';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { Option } from '@epcbuilder/lib/utils/generic';
import useJobSurvey from '@/hooks/jobs/useJobSurvey';
import { SurveyInstallationStatuses } from '@/models/job';
import { postSurveyStatus } from '@/network/surveys';

const JobSurveyStatus = ({
  surveyStatuses,
  job,
}: {
  surveyStatuses: SurveyInstallationStatuses[];
  job: Job | undefined;
}) => {
  const { jobSurvey, mutate: refetchJobSurvey } = useJobSurvey({ id: job?.id });

  const [editMode, setEditMode] = useState<boolean>(false);

  const defaultValues = {
    status: jobSurvey?.surveyStatusID,
  };

  const statusOptions: Option<number>[] = surveyStatuses?.map((status) => {
    return {
      value: status.key,
      label: status.value,
    };
  });

  const { control, watch, setError, setValue } = useForm<{ status: number }>({ defaultValues });
  const value = watch('status');

  const handleStatusUpdate = useCallback(async () => {
    try {
      await postSurveyStatus({ id: jobSurvey?.id, surveyStatusId: value });
      toast.success('Survey status successfully updated', { toastId: 'survey-status-success' });
      await refetchJobSurvey();
      setEditMode(false);
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<{ status: number }>(setError, errors);
      handleUnknownDetail(error);
    }
  }, [jobSurvey?.id, refetchJobSurvey, setError, value]);

  useEffect(() => {
    if (jobSurvey) {
      setValue('status', jobSurvey.surveyStatusID);
    }
  }, [jobSurvey, setValue]);

  return (
    <div className="flex flex-row gap-2">
      {editMode ? (
        <div>
          <Select
            inline={true}
            control={control}
            id="status"
            name="status"
            title="select survey status"
            label="Status"
            placeholder=""
            options={statusOptions}
            className="w-56"
          />
        </div>
      ) : (
        <>
          <p>Status:</p>
          <p id="survey-status" className="font-bold">
            {jobSurvey?.surveyStatusID ? SurveyStatusStrings[jobSurvey?.surveyStatusID] : 'Unknown'}
          </p>
        </>
      )}
      {editMode ? (
        <button id="edit-survey-status-button" type="button" onClick={() => handleStatusUpdate()}>
          <MdCheck className="text-primary" />
        </button>
      ) : (
        <button id="save-survey-status-button" type="button" onClick={() => setEditMode(true)}>
          <MdEdit className="text-primary" />
        </button>
      )}
    </div>
  );
};

export default JobSurveyStatus;
