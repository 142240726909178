import React from 'react';
import { MdOutlineCheck } from 'react-icons/md';

type CheckboxProps = Omit<JSX.IntrinsicElements['input'], 'type'> & { label?: string; labelclassname?: string };

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { id, label, labelclassname } = props;

  const baseClassNames = 'h-6 w-6 flex justify-center items-center border-2 rounded';

  return (
    <label className="relative flex cursor-pointer flex-row items-center gap-2" htmlFor={id}>
      <input type="checkbox" ref={ref} {...props} className="peer hidden" />
      <div
        className={`${baseClassNames} border-primary peer-disabled:border-neutral bg-color-secondary top-1/2 cursor-pointer`}
      />
      <MdOutlineCheck
        size={22}
        className="peer-checked:bg-primary peer-disabled:text-neutral absolute left-0.5 top-1/2 hidden translate-x-[-3%] translate-y-[-50%] rounded text-white peer-checked:block"
      />
      {label && <p className={`${labelclassname} text-color peer-disabled:text-neutral`}>{label}</p>}
    </label>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
