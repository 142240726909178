import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FilteredDatePicker, Loading, Modal } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { AbsenceForm, AbsenceFormData } from '@epcbuilder/lib/models/assessors';
import { Booking } from '@epcbuilder/lib/models/booking';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { checkBookingConflicts } from '@/utils/absenceUtils';
import SelectAbsenceSlot from '../SelectAbsenceSlot';
import SelectAbsenceType from '../SelectAbsenceType';

const absenceSchema = yup.object().shape({
  absenceDateStart: yup
    .date()
    .required('Start date must not be empty')
    .max(yup.ref('absenceDateEnd'), 'Start date cannot be after end date'),
  absenceDateEnd: yup
    .date()
    .required('End date must not be empty')
    .min(yup.ref('absenceDateStart'), 'End date cannot be before start date'),
  absenceTypeId: yup.string().required('Absence type must not be empty'),
  slotStart: yup.string().required('Slot must not be empty'),
  slotEnd: yup.string().required('Slot must not be empty'),
  notes: yup.string(),
});

const AbsenceFormModal = ({
  defaultValues,
  onClose,
  onSubmit,
  title,
  successMessage,
  handleDelete,
  booking,
}: {
  defaultValues: AbsenceFormData;
  onClose: () => void;
  onSubmit: SubmitHandler<AbsenceFormData>;
  title: string;
  successMessage?: string;
  handleDelete?: () => void;
  booking?: Booking[];
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<AbsenceFormData>({
    defaultValues,
    resolver: yupResolver(absenceSchema),
    reValidateMode: 'onSubmit',
  });

  const [availableSlots, setAvailableSlots] = useState({ am: true, pm: true });

  const absenceDateStart = useWatch({ control, name: 'absenceDateStart' });
  const absenceDateEnd = useWatch({ control, name: 'absenceDateEnd' });

  useEffect(() => {
    const updateAvailableSlots = () => {
      const selectedStartDate = new Date(absenceDateStart).toISOString().split('T')[0];
      const selectedEndDate = new Date(absenceDateEnd).toISOString().split('T')[0];

      const newAvailableSlots = { am: true, pm: true };

      booking?.forEach((b) => {
        const surveyDate = new Date(b.surveyDate).toISOString().split('T')[0];
        const surveySlot = b.slot === 1 ? 'am' : 'pm';

        if (
          (selectedStartDate <= surveyDate && selectedEndDate >= surveyDate) ||
          selectedStartDate === surveyDate ||
          selectedEndDate === surveyDate
        ) {
          if (surveySlot === 'am') newAvailableSlots.am = false;
          if (surveySlot === 'pm') newAvailableSlots.pm = false;
        }
      });

      setAvailableSlots(newAvailableSlots);
    };

    if (absenceDateStart && absenceDateEnd) {
      updateAvailableSlots();
    }
  }, [absenceDateStart, absenceDateEnd, booking]);

  const today = new Date();
  today.setHours(0, 0, 0);

  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 92);
  maxDate.setHours(0, 0, 0);

  const daysToRemove: Date[] = [];

  for (let d = new Date(today); d <= maxDate; d.setDate(d.getDate() + 1)) {
    const day = d.getDay();
    const currentDate = new Date(d);

    const bookingCount =
      booking?.filter((b) => {
        const surveyDate = new Date(b.surveyDate);
        return (
          surveyDate.getFullYear() === currentDate.getFullYear() &&
          surveyDate.getMonth() === currentDate.getMonth() &&
          surveyDate.getDate() === currentDate.getDate()
        );
      }).length || 0;

    // Add the day to daysToRemove if it's a weekend or if there are 2 or more bookings
    if (day === 0 || day === 6 || bookingCount >= 2) {
      daysToRemove.push(new Date(currentDate));
    }
  }

  const handleFormSubmit: SubmitHandler<AbsenceFormData> = async (data) => {
    const bookingConflict = checkBookingConflicts(data, booking || []);

    if (bookingConflict) {
      setError('absenceDateStart', {
        message: `Absence can't be booked during dates that already have a survey booking.`,
      });
      return;
    }

    if (!availableSlots.am && !availableSlots.pm) {
      setError('absenceDateStart', {
        message: 'No slots available for the selected date range.',
      });
      return;
    }

    try {
      await onSubmit(data);
      toast.success(`${successMessage}`, { toastId: `${title.toLowerCase().replace(' ', '-')}-success` });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<AbsenceForm>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  if (isSubmitting) {
    return <Loading />;
  }

  const isEditMode = title.toLowerCase().includes('edit');

  return (
    <Modal id="absence-modal" onClose={onClose}>
      <h1 className="font-header text-2xl">{title}</h1>
      <form className="mt-4 flex flex-col">
        <div className="flex w-full flex-row justify-between gap-4">
          <div className="relative w-full">
            <FilteredDatePicker
              daysToRemove={daysToRemove!}
              maxDate={maxDate}
              id="absenceDateStart"
              control={control}
              name="absenceDateStart"
              label="Start"
              className="border-blue-lighter bg-color-secondary dark:border-primary-darker"
              boldLabel
              error={errors.absenceDateStart?.message}
              icon
            />
          </div>
          <div className="mt-[30px] w-full">
            <SelectAbsenceSlot
              control={control}
              error={errors.slotStart?.message}
              id="slotStart"
              availableSlots={availableSlots}
              bookingError={!availableSlots.am && !availableSlots.pm}
            />
          </div>
        </div>
        <div className="mt-4 flex flex-row justify-between gap-4">
          <div className="relative w-full">
            <FilteredDatePicker
              daysToRemove={daysToRemove!}
              maxDate={maxDate}
              id="absenceDateEnd"
              control={control}
              name="absenceDateEnd"
              label="End"
              className="border-blue-lighter bg-color-secondary dark:border-primary-darker"
              boldLabel
              error={errors.absenceDateEnd?.message}
              icon
            />
          </div>
          <div className="mt-8 w-full">
            <SelectAbsenceSlot
              control={control}
              error={errors.slotEnd?.message}
              id="slotEnd"
              availableSlots={availableSlots}
            />
          </div>
        </div>
        <p className="mb-2 ml-2 mt-4 font-bold">Absence Type</p>
        <SelectAbsenceType error={errors.absenceTypeId?.message} control={control} />
        <p className="mb-2 ml-2 mt-4 font-bold">Notes</p>
        <textarea
          {...register('notes')}
          id="notes"
          name="notes"
          className="text-color bg-color-secondary border-blue-lighter dark:border-primary-darker focus:border-primary-lighter h-20 w-full rounded-xl border-2 px-4 text-base outline-none"
        />
        <div className="mt-8 flex gap-6">
          <div className={`flex gap-4 justify-${isEditMode ? 'end' : 'center'} w-full`}>
            {isEditMode ? (
              <NewButton id="assessor-delete" variant="delete" text="Delete" onClick={handleDelete} />
            ) : (
              <NewButton id="cancel" variant="secondary" text="Cancel" onClick={onClose} />
            )}
            <NewButton
              id="absence-submit"
              type="submit"
              variant="primary"
              text="Save Changes"
              onClick={handleSubmit(handleFormSubmit)}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AbsenceFormModal;
