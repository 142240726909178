import React from 'react';
import { Children } from '../utils';

export const Accordion = ({
  children,
  onClick,
  accordionOpen,
  accordionChildren,
}: {
  children?: Children;
  onClick?: () => void;
  accordionOpen?: boolean;
  accordionChildren?: Children;
}) => {
  return (
    <>
      <button
        type="button"
        className={`${
          accordionOpen && 'border-b-2'
        } border-neutral flex min-h-20 w-full flex-row items-center justify-between p-4 ${onClick && 'cursor-pointer'}`}
        onClick={onClick}
      >
        {children}
      </button>
      {accordionOpen && <div className="flex min-h-28 flex-row items-center p-4">{accordionChildren}</div>}
    </>
  );
};

//This will be our accordion moving forward
export const NewAccordion = ({
  children,
  onClick,
  accordionOpen,
  accordionChildren,
}: {
  children?: Children;
  onClick?: () => void;
  accordionOpen?: boolean;
  accordionChildren?: Children;
}) => {
  return (
    <>
      <button
        type="button"
        className={`${
          accordionOpen && 'border-b-2'
        } border-blue dark:border-primary flex w-full flex-row items-center justify-between p-2 ${onClick && 'cursor-pointer'}`}
        onClick={onClick}
      >
        {children}
      </button>
      {accordionOpen && <div className="flex min-h-20 flex-row items-center">{accordionChildren}</div>}
    </>
  );
};
