import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import useJobUser from '@/hooks/jobs/useJobUser';

const JobUserData = ({ id }: { id: string | undefined }) => {
  const { jobUser, error } = useJobUser({ id });

  useEffect(() => {
    if (error) {
      const { detail } = error;
      if (detail === "User associated with property doesn't exist.") {
        toast.error('The user associated with this property does not exist.');
      }
    }
  }, [error]);

  return (
    <div className="border-neutral-dark hover:border-primary relative gap-4 rounded-xl border-2 p-4">
      <div className="flex flex-col gap-2">
        <h1>Owner:</h1>
        <p>
          <span className="font-bold">Name: </span>
          <span id="user-fullname">{`${jobUser?.firstName} ${jobUser?.lastName}`}</span>
        </p>
        <p>
          <span className="font-bold">Email: </span>
          <span id="user-email">{`${jobUser?.email}`}</span>
        </p>
        <p>
          <span className="font-bold">Phone: </span>
          <span id="user-phone">{`${jobUser?.phone}`}</span>
        </p>
        {jobUser?.secondaryPhone && (
          <p>
            <span className="font-bold">Secondary Phone: </span>
            <span id="user-secondary-phone">{`${jobUser?.secondaryPhone}`}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default JobUserData;
