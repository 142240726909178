import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { NewSelect } from '@epcbuilder/lib/components';

const absenceTypeOptions = [
  { value: '1', label: 'Holiday' },
  { value: '2', label: 'Sickness' },
  { value: '3', label: 'Other' },
  { value: '4', label: 'Bank Holiday' },
];

const SelectAbsenceType = <T extends FieldValues>({ control, error }: { control: Control<T>; error?: string }) => {
  return (
    <div id="absence-type-dropdown" className="flex flex-1 flex-col flex-wrap gap-2 sm:flex-row sm:items-center">
      <div className="w-full">
        <NewSelect
          className="rounded-xl"
          control={control}
          id="absenceTypeId"
          name={'absenceTypeId' as Path<T>}
          title="Absence absenceTypeId"
          options={absenceTypeOptions}
        />
      </div>
      {error && (
        <div id="absence-type-error" className="ml-2 flex flex-col">
          <p className="text-error text-xs">{error}</p>
        </div>
      )}
    </div>
  );
};
export default SelectAbsenceType;
