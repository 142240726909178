import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DatePicker, Loading, Modal, NewSelect, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { booleanOptions } from '@epcbuilder/lib/models/generic';
import { JobInstallation } from '@epcbuilder/lib/models/jobs';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { InstallationBooking, InstallationSaveBooking } from '@/models/job';
import { postInstallationSaveBooking } from '@/network/installations';

const JobInstallationModal = ({
  onClose,
  jobInstallation,
  installationBooking,
}: {
  onClose: () => void;
  jobInstallation: JobInstallation | undefined;
  installationBooking: InstallationBooking | undefined;
}) => {
  const defaultValues = {
    paid: installationBooking?.paid || false,
    selectedDateTime: installationBooking?.bookingDateTime ? new Date(installationBooking?.bookingDateTime) : undefined,
    assessor: installationBooking?.assessor || '',
    notes: installationBooking?.notes || '',
  };

  const {
    register,
    setError,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<InstallationSaveBooking>({ defaultValues });

  const onSubmit: SubmitHandler<InstallationSaveBooking> = async (data) => {
    try {
      await postInstallationSaveBooking({ id: jobInstallation?.id, data });
      toast.success('Installation booking updated', { toastId: 'installation-booking-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<InstallationSaveBooking>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="job-pashub-modal" onClose={onClose}>
      <h1>Edit Installation Details</h1>
      <form className="mt-8 flex flex-col gap-4">
        <NewSelect
          control={control}
          id="paid"
          name="paid"
          title="select paid"
          label="Paid"
          options={booleanOptions}
          className="rounded-xl"
        />
        <DatePicker
          showTimeSelect={true}
          id="selectedDateTime"
          control={control}
          name="selectedDateTime"
          label="Actual booking Date/Time"
        />
        <TextInput id="assessor" {...register('assessor')} label="Installer" error={errors.assessor?.message} />
        <TextInput id="notes" {...register('notes')} label="Notes" error={errors.notes?.message} />
        <div className="mt-4 flex flex-row items-center justify-between gap-4">
          <NewButton id="cancel-button" variant="secondary" onClick={onClose} text="Cancel" />
          <NewButton
            id="access-submit"
            loading={isSubmitting}
            variant="primary"
            text="Submit"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default JobInstallationModal;
