import { api } from '@epcbuilder/lib/utils';

export const putReportPropertyOverviewSummary = async ({ id, data }: { id?: string; data: { value: string } }) => {
  return await api.put(`/report/${id}/property-overview/summary`, data);
};

export const putReportPropertyOverviewFrontElevation = async ({
  id,
  data,
}: {
  id?: string;
  data: { value: string };
}) => {
  return await api.put(`/report/${id}/property-overview/front-elevation`, data);
};

export const putReportPropertyOverviewRearElevation = async ({
  id,
  data,
}: {
  id?: string;
  data: { value: string };
}) => {
  return await api.put(`/report/${id}/property-overview/rear-elevation`, data);
};

export const putReportPropertyOverviewFloorPlan = async ({ id, data }: { id?: string; data: { value: string } }) => {
  return await api.put(`/report/${id}/property-overview/floor-plan`, data);
};

export const putReportPropertyOverviewFloor1Plan = async ({ id, data }: { id?: string; data: { value: string } }) => {
  return await api.put(`/report/${id}/property-overview/floor-1-plan`, data);
};

export const putReportPropertyOverviewFloor2Plan = async ({ id, data }: { id?: string; data: { value: string } }) => {
  return await api.put(`/report/${id}/property-overview/floor-2-plan`, data);
};

export const deleteReportPropertyOverviewFloor1Plan = async ({ id }: { id?: string }) => {
  return await api.delete(`/report/${id}/property-overview/floor-1-plan`);
};

export const deleteReportPropertyOverviewFloor2Plan = async ({ id }: { id?: string }) => {
  return await api.delete(`/report/${id}/property-overview/floor-2-plan`);
};

export const putReportPropertyOverviewRoof = async ({ id, data }: { id?: string; data: { value: string } }) => {
  return await api.put(`/report/${id}/property-overview/fabric-and-insulation/roof-image`, data);
};

export const putReportPropertyOverviewRoofDescription = async ({
  id,
  data,
}: {
  id?: string;
  data: { value: string };
}) => {
  return await api.put(`/report/${id}/property-overview/fabric-and-insulation/roof`, data);
};

export const putReportPropertyOverviewWindows = async ({ id, data }: { id?: string; data: { value: string } }) => {
  return await api.put(`/report/${id}/property-overview/fabric-and-insulation/windows-image`, data);
};

export const putReportPropertyOverviewWindowsDescription = async ({
  id,
  data,
}: {
  id?: string;
  data: { value: string };
}) => {
  return await api.put(`/report/${id}/property-overview/fabric-and-insulation/windows`, data);
};

export const putReportPropertyOverviewWalls = async ({ id, data }: { id?: string; data: { value: string } }) => {
  return await api.put(`/report/${id}/property-overview/fabric-and-insulation/walls-image`, data);
};

export const putReportPropertyOverviewWallsDescription = async ({
  id,
  data,
}: {
  id?: string;
  data: { value: string };
}) => {
  return await api.put(`/report/${id}/property-overview/fabric-and-insulation/walls`, data);
};

export const putReportPropertyOverviewFloor = async ({ id, data }: { id?: string; data: { value: string } }) => {
  return await api.put(`/report/${id}/property-overview/fabric-and-insulation/floor-image`, data);
};

export const putReportPropertyOverviewFloorDescription = async ({
  id,
  data,
}: {
  id?: string;
  data: { value: string };
}) => {
  return await api.put(`/report/${id}/property-overview/fabric-and-insulation/floor`, data);
};

export const putReportPropertyOverviewHeating = async ({ id, data }: { id?: string; data: { value: string } }) => {
  return await api.put(`/report/${id}/property-overview/heating/photo`, data);
};

export const putReportPropertyOverviewHeatingDescription = async ({
  id,
  data,
}: {
  id?: string;
  data: { value: string };
}) => {
  return await api.put(`/report/${id}/property-overview/heating/description`, data);
};

export const putReportPropertyOverviewHeatingControls = async ({
  id,
  data,
}: {
  id?: string;
  data: { value: string };
}) => {
  return await api.put(`/report/${id}/property-overview/heating-controller/photo`, data);
};

export const putReportPropertyOverviewHeatingControlsDescription = async ({
  id,
  data,
}: {
  id?: string;
  data: { value: string };
}) => {
  return await api.put(`/report/${id}/property-overview/heating-controller/description`, data);
};

export const putReportPropertyOverviewLighting = async ({ id, data }: { id?: string; data: { value: string } }) => {
  return await api.put(`/report/${id}/property-overview/lighting/photo`, data);
};

export const putReportPropertyOverviewLightingFittings = async ({
  id,
  data,
}: {
  id?: string;
  data: { value: string };
}) => {
  return await api.put(`/report/${id}/property-overview/lighting/total-fittings`, data);
};
export const putReportPropertyOverviewLightingLowEnergy = async ({
  id,
  data,
}: {
  id?: string;
  data: { value: string };
}) => {
  return await api.put(`/report/${id}/property-overview/lighting/low-energy-lights`, data);
};

export const putReportSchemaEligible = async ({ id, eligible }: { id: string; eligible: boolean }) => {
  return await api.put(`/scheme/report-scheme/${id}/${eligible}`);
};

export const putReportActualEpc = async ({ jobId, data }: { jobId?: string; data: { rating: string } }) => {
  return await api.put(`/report/${jobId}/actualEpc`, data);
};

export const getDoesReportAlreadyExists = async ({ jobId }: { jobId?: string }) => {
  return await api.get(`/report/${jobId}/reportExists`);
};
