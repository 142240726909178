import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Modal, TextAreaInput, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { Job } from '@epcbuilder/lib/models/jobs';
import { AdminContactEmail, Property } from '@epcbuilder/lib/models/properties';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { EMAIL_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useJobUser from '@/hooks/jobs/useJobUser';
import { postAdminContactEmail } from '@/network/emails';

const contactEmailSchema = yup.object().shape({
  propertyId: yup.string().required('Property ID must not be empty'),
  recipientEmail: yup
    .string()
    .matches(EMAIL_REGEX, 'Please enter a valid email address')
    .required('Email must not be empty'),
  emailSubject: yup.string().required('The subject line must not be empty'),
  emailBody: yup.string().required('Message must not be empty'),
});

const AdminContactEmailModal = ({
  id,
  property,
  onClose,
}: {
  id: string | undefined;
  job: Job;
  property: Property;
  onClose: () => void;
}) => {
  const { jobUser } = useJobUser({ id });

  const defaultValues: AdminContactEmail = {
    propertyId: property.id,
    recipientEmail: `${jobUser?.email}`,
    emailSubject: '',
    emailBody: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AdminContactEmail>({
    defaultValues,
    resolver: yupResolver(contactEmailSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<AdminContactEmail> = async (data: AdminContactEmail) => {
    try {
      await postAdminContactEmail(data);
      toast.success('Owner has been emailed', { toastId: 'email-contact-success' });
      onClose();
    } catch (error: unknown) {
      handleUnknownDetail(error);
      toast.error('There was a problem sending the email. Please try again.');
    }
  };

  return (
    <Modal id="email-contact-modal" onClose={onClose}>
      <h1>Send an email</h1>
      <form className="flex flex-col gap-4">
        <p className="text-sm">This email will be sent to the address listed in Owner Details.</p>
        <TextInput disabled hidden id="propertyId" {...register('propertyId')} />
        <TextInput
          disabled
          {...register('recipientEmail')}
          id="recipientEmail"
          title="Email address"
          error={errors.recipientEmail?.message}
        />
        <TextInput
          {...register('emailSubject')}
          id="emailSubject"
          title="Email subject"
          placeholder="Subject..."
          error={errors.emailSubject?.message}
        />
        <TextAreaInput
          {...register('emailBody', { required: true })}
          id="emailBody"
          title="Email body"
          placeholder="Enter your message here..."
          error={errors.emailBody?.message}
          rows={5}
        />
        <div className="mt-4 flex flex-row justify-between gap-4">
          <NewButton id="cancel-button" variant="secondary" onClick={onClose} text="Cancel" />
          <NewButton
            id="send-email-button"
            loading={isSubmitting}
            variant="primary"
            text="Send Email"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AdminContactEmailModal;
