import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { Children } from '../utils';

type ButtonProps = Omit<JSX.IntrinsicElements['button'], 'children' | 'style'> & {
  children?: Children;
  type?: 'reset' | 'submit';
  style?: 'primary' | 'secondary' | 'delete' | 'custom';
  loading?: boolean;
  overrideBaseClassnames?: boolean;
  widthFitContent?: boolean;
  renderChildrenOutsideParagraph?: boolean;
};

export const Button = (props: ButtonProps) => {
  const {
    children,
    type,
    className,
    style = 'primary',
    loading,
    disabled,
    overrideBaseClassnames,
    widthFitContent = false,
    renderChildrenOutsideParagraph,
    ...rest
  } = props;

  const baseClassNames = `${className} text-base font-header h-12 shadow rounded-[24px] flex items-center justify-center px-4 min-w-fit ${widthFitContent ? 'w-fit' : 'w-full'}`;

  const primaryStyleClassNames =
    'bg-light border-2 border-primary dark:border-none dark:bg-primary text-primary dark:text-light hover:bg-primary-lighter hover:border-primary-lighter dark:hover:bg-primary-dark dark:active:bg-dark-lighter active:bg-primary active:border-primary active:text-dark-dark disabled:text-neutral-darker disabled:bg-neutral-lighter disabled:border-neutral-darkest dark:disabled:text-neutral-darktext dark:disabled:bg-dark-lightest';

  const secondaryStyleClassNames =
    'bg-color text-primary border-2 border-blue hover:opacity-80 disabled:text-neutral-darker disabled:bg-neutral-lighter disabled:border-none dark:disabled:text-neutral-darktext dark:disabled:bg-dark-lightest dark:disabled:border-none';

  const deleteStyleClassNames = 'bg-color text-secondary-dark border-2 border-secondary-dark';

  const customStyleClassNames =
    'bg-blue text-dark border-primary-lighter dark:bg-primary dark:text-light hover:bg-primary-lighter hover:border-primary-lighter dark:hover:bg-primary-dark dark:disabled:text-neutral-darktext dark:disabled:bg-dark-lightest h-12 w-full rounded-[24px] border-2 px-4 font-bold dark:border-none';

  return (
    <button
      {...rest}
      type={type || 'button'}
      className={`${overrideBaseClassnames ? className : baseClassNames} ${
        style === 'primary'
          ? primaryStyleClassNames
          : style === 'secondary'
            ? secondaryStyleClassNames
            : style === 'delete'
              ? deleteStyleClassNames
              : customStyleClassNames
      }`}
      disabled={loading || disabled}
    >
      {loading ? <BarLoader color="#2a9c8e" /> : renderChildrenOutsideParagraph ? children : <p>{children}</p>}
    </button>
  );
};

Button.displayName = 'Button';

export const IconButton = ({
  children,
  id,
  style = 'primary',
  onClick,
  disabled,
  className = '',
}: {
  children: JSX.Element;
  id?: string;
  style?: 'primary' | 'secondary' | 'delete';
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}) => {
  const baseClassNames = 'rounded-full border-2 h-12 w-12 flex justify-center items-center cursor-pointer';
  const primaryClassNames =
    'bg-blue-lighter dark:bg-primary-darker hover:bg-blue-dark dark:hover:bg-[#52AFA4] border-none';
  const secondaryClassNames =
    'border-blue-lighter hover:border-blue-dark dark:border-primary-darker hover:dark:border-primary bg-color-secondary text-color';
  const deleteStyleClassNames =
    'bg-color-secondary text-error-light border-error-light hover:border-error hover:text-error';

  if (disabled) {
    return <div className="rounded-full">{children}</div>;
  }

  return (
    <button
      type="button"
      id={id}
      onClick={onClick}
      className={`${baseClassNames} ${
        style === 'primary' ? primaryClassNames : style === 'secondary' ? secondaryClassNames : deleteStyleClassNames
      } ${className}`}
    >
      {children}
    </button>
  );
};

// This will be as our button moving forward.
type NewButtonVariant = 'primary' | 'secondary' | 'delete';

type NewButtonProps = Omit<JSX.IntrinsicElements['button'], 'children' | 'style'> & {
  id: string;
  icon?: JSX.Element;
  text?: string;
  variant?: NewButtonVariant;
  loading?: boolean;
  onClick?: () => void;
};

const baseClassNames =
  'text-color dark:border-primary border-blue flex h-12 cursor-pointer flex-row items-center rounded-[20px] border-2 px-3 w-full justify-center font-bold';

const styleClassNames: Record<NewButtonVariant, string> = {
  primary: 'bg-blue-lighter dark:bg-primary-darker hover:bg-blue-dark dark:hover:bg-[#52AFA4] border-none',
  secondary:
    'border-blue-lighter hover:border-blue-dark bg-color-secondary dark:hover:border-[#52AFA4] dark:border-primary-darker',
  delete:
    'bg-color-secondary border-secondary-dark hover:border-[#BC5A41] dark:hover:bg-secondary-dark dark:border-secondary-dark',
};

export const NewButton = (props: NewButtonProps) => {
  const { id, text, icon, variant = 'primary', loading, onClick } = props;
  const finalClassNames = `${baseClassNames} ${styleClassNames[variant]}`;

  return (
    <button type="button" id={id} onClick={onClick} className={finalClassNames}>
      {loading ? (
        <BarLoader color="#2a9c8e" />
      ) : (
        <div className="flex flex-row gap-2">
          {icon}
          {text && <p>{text}</p>}
        </div>
      )}
    </button>
  );
};

interface SidebarButtonProps {
  id: string;
  icon: JSX.Element;
  text: string;
  href?: string;
  active?: string;
  setActive?: React.Dispatch<React.SetStateAction<string>>;
  onClick?: () => void;
  showText?: boolean;
}

export const SidebarButton: React.FC<SidebarButtonProps> = ({
  id,
  icon,
  text,
  href,
  active,
  setActive,
  onClick,
  showText = false,
}) => {
  const navigate = useNavigate();
  const baseClassNames = 'flex font-bold flex-row items-center h-12 rounded-xl px-3 cursor-pointer border-2';
  const activeClassNames = active === id ? 'text-primary border-none' : 'hover:text-[#7ac2b9] border-none';

  const handleClick = () => {
    setActive?.(id);
    if (href) {
      navigate(href);
    }
    onClick?.();
  };

  return (
    <button id={`sidebar-${id}`} className={`${baseClassNames} ${activeClassNames}`} onClick={handleClick}>
      {icon}
      <p
        id={active === id ? 'sidebar-active' : undefined}
        className={`ml-4 ${showText ? 'block' : 'hidden group-hover:block lg:block'}`}
      >
        {text}
      </p>
    </button>
  );
};
